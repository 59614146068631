<template>
  <div style="padding: 20px; min-height: 630px; background-color: rgba(221, 225, 230, 1); border: solid 1px #ccc">
    <div class="row">
      <div class="col-md-12">
        <span class="title_bi mr-4">Tu Facturacion</span>
        <input name="desde" type="date" v-model="from_date" class="mt-1" style="font-size: 14px; cursor: pointer" title="Desde" @change="fillFacturacion" />
        -
        <input name="hasta" type="date" v-model="to_date" class="mt-1" style="font-size: 14px; cursor: pointer" title="Hasta" @change="fillFacturacion" />
        -
        <div style="max-width: 90px; display: inline-block" class="ml-2">
          <v-combobox v-model="currency" autocomplete="off" item-text="name" item-value="id" :items="monedas" class="pt-0 mt-0" dense @change="fillFacturacion"></v-combobox>
        </div>
        -
        <div style="max-width: 350px; display: inline-block" class="ml-2">
          <v-combobox
            v-model="emitter"
            autocomplete="off"
            data-vv-as="emitter"
            name="emitter"
            item-text="name"
            item-value="id"
            :items="emitters"
            label="Emisor"
            clearable
            class="pt-0 mt-0"
            dense
            @change="fillFacturacion"
          ></v-combobox>
        </div>
        <div>
          <span class="subtitle-business-card ml-0">Los totales no incluyen IVA. </span>
        </div>
        <v-card class="mt-4" style="min-height: 400px">
          <v-card-title> </v-card-title>
          <div class="col-md-12">
            <v-chip outlined color="primary"> Volumen de facturacion: {{ currency.name }} {{ new Intl.NumberFormat("en-EN").format(total_facturacion) }} </v-chip>
            <v-chip outlined class="ml-2"> Cantidad de comprobantes: {{ cant_comprobantes }} </v-chip>
          </div>

          <apexchart type="area" height="250" ref="statFacturacion" :options="chartOptionsFacturacion" :series="seriesFacturacion"></apexchart>
        </v-card>
      </div>

      <div class="col-md-4">
        <v-card style="min-height: 420px">
          <v-card-title class="pb-0">
            <div class="col-md-10">
              <p class="title_bi">Por tipo de comprobante</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="col-md-2" style="margin-top: -15px">
                  <v-icon class="ml-2" small color="grey" dark v-bind="attrs" v-on="on"> help_outline </v-icon>
                </div>
              </template>
              <span> Distribucion de facturacion segun tipo de comprobante</span>
            </v-tooltip>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <apexchart height="300px" type="pie" ref="statFacturacionComprobante" :options="optionsFacturacionComprobante" :series="seriesFacturacionComprobante"></apexchart>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-md-4">
        <v-card style="min-height: 420px">
          <v-card-title class="pb-0">
            <div class="col-md-10">
              <p class="title_bi">Por cliente</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="col-md-2" style="margin-top: -15px">
                  <v-icon class="ml-2" small color="grey" dark v-bind="attrs" v-on="on"> help_outline </v-icon>
                </div>
              </template>
              <span> Distribucion de facturacion segun clientes</span>
            </v-tooltip>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <apexchart height="300px" type="pie" ref="statFacturacionCliente" :options="optionsFacturacionCliente" :series="seriesFacturacionCliente"></apexchart>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-md-4">
        <v-card style="min-height: 420px">
          <v-card-title class="pb-0">
            <div class="col-md-10">
              <p class="title_bi">Por rubro de producto</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="col-md-2" style="margin-top: -15px">
                  <v-icon class="ml-2" small color="grey" dark v-bind="attrs" v-on="on"> help_outline </v-icon>
                </div>
              </template>
              <span> Distribucion de facturacion segun rubro de producto</span>
            </v-tooltip>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <apexchart height="300px" type="pie" ref="statFacturacionCategoria" :options="optionsFacturacionCategoria" :series="seriesFacturacionCategoria"></apexchart>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="row">
      <div class="col-md-12">
        <span class="title_bi mr-4">Tu Gasto</span>
        <input name="desde" type="date" v-model="from_date_gasto" class="mt-1" style="font-size: 14px; cursor: pointer" title="Desde" @change="fillGasto" />
        -
        <input name="hasta" type="date" v-model="to_date_gasto" class="mt-1" style="font-size: 14px; cursor: pointer" title="Hasta" @change="fillGasto" />
        -
        <div style="max-width: 90px; display: inline-block" class="ml-2">
          <v-combobox v-model="currency_gasto" autocomplete="off" item-text="name" item-value="id" :items="monedas" class="pt-0 mt-0" dense @change="fillGasto"></v-combobox>
        </div>
        -
        <div style="max-width: 350px; display: inline-block" class="ml-2">
          <v-combobox
            v-model="receiver_gasto"
            autocomplete="off"
            data-vv-as="receiver_gasto"
            name="receiver_gasto"
            item-text="name"
            item-value="id"
            :items="emitters"
            label="Receptor"
            clearable
            class="pt-0 mt-0"
            dense
            @change="fillGasto"
          ></v-combobox>
        </div>

        <div>
          <span class="subtitle-business-card ml-0">Los totales no incluyen IVA. </span>
        </div>

        <v-card class="mt-4" style="min-height: 400px">
          <v-card-title> </v-card-title>
          <div class="col-md-12">
            <v-chip outlined color="primary"> Volumen de gasto: {{ currency_gasto.name }} {{ new Intl.NumberFormat("en-EN").format(total_gastos) }} </v-chip>
          </div>

          <apexchart type="area" height="250" ref="statGastos" :options="chartOptionsGastos" :series="seriesGastos"></apexchart>
        </v-card>
      </div>

      <div class="col-md-6">
        <v-card style="min-height: 420px">
          <v-card-title class="pb-0">
            <div class="col-md-10">
              <p class="title_bi">Por proveedor</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="col-md-2" style="margin-top: -15px">
                  <v-icon class="ml-2" small color="grey" dark v-bind="attrs" v-on="on"> help_outline </v-icon>
                </div>
              </template>
              <span> Distribucion de gastos segun proveedor</span>
            </v-tooltip>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <apexchart height="300px" type="pie" ref="statGastosProveedor" :options="optionsGastosProveedor" :series="seriesGastosProveedor"></apexchart>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-md-6">
        <v-card style="min-height: 420px">
          <v-card-title class="pb-0">
            <div class="col-md-10">
              <p class="title_bi">Por subcentros de costos</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="col-md-2" style="margin-top: -15px">
                  <v-icon class="ml-2" small color="grey" dark v-bind="attrs" v-on="on"> help_outline </v-icon>
                </div>
              </template>
              <span> Distribucion de gastos segun categoria</span>
            </v-tooltip>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <apexchart height="300px" type="pie" ref="statGastosCategoria" :options="optionsGastosCategoria" :series="seriesGastosCategoria"></apexchart>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { metricsService } from "@/libs/ws/metricsService.js";
import { emmiterService } from "@/libs/ws/emmiterService";
import { generalService } from "@/libs/ws/generalService";
import Constant from "@/constants/sections";
export default {
  components: {},
  data() {
    return {
      loading: false,
      menu: false,
      service: metricsService,
      from_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      to_date: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      currency: { id: "ARS", name: "ARS" },
      emitter: null,
      emitters: [],
      from_date_gasto: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      to_date_gasto: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      currency_gasto: { id: "ARS", name: "ARS" },
      receiver_gasto: null,
      total_facturacion: 0,
      total_gastos: 0,
      cant_comprobantes: 0,
      monedas: metricsService.getMonedas(),
      headers: [
        { text: "Ene", sortable: false },
        { text: "Feb", sortable: false },
        { text: "Mar", sortable: false },
        { text: "Abr", sortable: false },
        { text: "May", sortable: false },
        { text: "Jun", sortable: false },
        { text: "Jul", sortable: false },
        { text: "Ago", sortable: false },
        { text: "Sep", sortable: false },
        { text: "Oct", sortable: false },
        { text: "Nov", sortable: false },
        { text: "Dic", sortable: false },
      ],
      months: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      optionsGastosProveedor: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [],
        legend: {
          show: true,
          position: "bottom",
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return new Intl.NumberFormat("en-EN").format(val);
            },
          },
        },
        colors: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      optionsGastosCategoria: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [],
        legend: {
          show: true,
          position: "bottom",
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return new Intl.NumberFormat("en-EN").format(val);
            },
          },
        },
        colors: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      optionsFacturacionCategoria: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [],
        legend: {
          show: true,
          position: "bottom",
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return new Intl.NumberFormat("en-EN").format(val);
            },
          },
        },
        colors: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      optionsFacturacionComprobante: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [],
        legend: {
          show: true,
          position: "bottom",
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return new Intl.NumberFormat("en-EN").format(val);
            },
          },
        },
        colors: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      optionsFacturacionCliente: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [],
        legend: {
          show: true,
          position: "bottom",
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return new Intl.NumberFormat("en-EN").format(val);
            },
          },
        },
        colors: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      seriesGastos: [
        {
          name: "Gastos",
          data: [],
        },
      ],
      seriesFacturacion: [
        {
          name: "Facturación",
          data: [],
        },
      ],
      seriesFacturacionComprobante: [],
      seriesFacturacionCategoria: [],
      seriesFacturacionCliente: [],
      seriesGastosProveedor: [],
      seriesGastosCategoria: [],
      chartOptionsFacturacion: {
        chart: {
          type: "area",
          height: 180,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        colors: ["#4a7def", "#E91E63", "#9C27B0"],
        labels: [],
        legend: {
          horizontalAlign: "left",
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return new Intl.NumberFormat("en-EN").format(val);
            },
          },
        },
        xaxis: {
          categories: [],
          tickAmount: 3,
        },
      },
      chartOptionsGastos: {
        chart: {
          type: "area",
          height: 180,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        colors: ["#4a7def", "#E91E63", "#9C27B0"],
        labels: [],
        legend: {
          horizontalAlign: "left",
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return new Intl.NumberFormat("en-EN").format(val);
            },
          },
        },
        xaxis: {
          categories: [],
          tickAmount: 3,
        },
      },
    };
  },
  mounted() {
    this.fillFacturacion();
    this.fillGasto();
    this.loadEmitters();
  },
  watch: {},
  methods: {
    loadEmitters() {
      emmiterService.filter({ enabled: true }).then((response) => {
        this.emitters = response.result;
      });
    },
    fillGasto() {
      this.getGastos();
      this.getGastosByProveedor();
      this.getGastosByCategoria();
    },
    fillFacturacion() {
      this.getFacturacion();
      this.getFacturacionByComprobante();
      this.getFacturacionByCategoria();
      this.getFacturacionByCliente();
    },
    getFacturacion() {
      this.loading = true;

      this.total_facturacion = 0;
      this.cant_comprobantes = 0;

      metricsService
        .getFacturacion({
          from_date: this.from_date,
          to_date: this.to_date,
          currency: this.currency.id,
          emitter: this.emitter != null ? this.emitter.id : null,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          var arr = [];
          var xaxis = [];

          response.result.forEach((element) => {
            this.total_facturacion = this.total_facturacion + parseFloat(element.amount);
            this.cant_comprobantes = this.cant_comprobantes + parseInt(element.cantidad);
            arr.push(element.amount);
            xaxis.push(this.getDateFormat(element.cbte_fch));
          });

          this.seriesFacturacion[0].data = arr;
          this.$refs.statFacturacion.updateSeries(this.seriesFacturacion, false, true);
          this.$refs.statFacturacion.updateOptions({
            xaxis: {
              categories: xaxis,
              tickAmount: 10,
            },
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDateFormat(date) {
      var date_aux = date.split("-");
      var d = new Date(date);
      return date_aux[2] + " " + this.months[d.getMonth()];
    },
    getFacturacionByCliente() {
      metricsService
        .getFacturacionByCliente({
          from_date: this.from_date,
          to_date: this.to_date,
          currency: this.currency.id,
          emitter: this.emitter != null ? this.emitter.id : null,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          var arr = [];
          var labels = [];
          response.result.forEach((element) => {
            arr.push(parseFloat(element.imp_total));
            labels.push(element.name);
          });

          this.optionsFacturacionCliente = {
            labels: labels,
          };
          this.seriesFacturacionCliente = arr;
          this.$refs.statFacturacionCliente.updateSeries(this.seriesFacturacionCliente, false, true);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFacturacionByComprobante() {
      metricsService
        .getFacturacionByComprobante({
          from_date: this.from_date,
          to_date: this.to_date,
          currency: this.currency.id,
          emitter: this.emitter != null ? this.emitter.id : null,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          var arr = [];
          var labels = [];
          response.result.forEach((element) => {
            arr.push(parseFloat(element.amount));
            labels.push(element.cbte_tipo);
          });

          this.optionsFacturacionComprobante = {
            labels: labels,
          };
          this.seriesFacturacionComprobante = arr;
          this.$refs.statFacturacionComprobante.updateSeries(this.seriesFacturacionComprobante, false, true);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFacturacionByCategoria() {
      metricsService
        .getFacturacionByCategoria({
          from_date: this.from_date,
          to_date: this.to_date,
          currency: this.currency.id,
          emitter: this.emitter != null ? this.emitter.id : null,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          var arr = [];
          var labels = [];
          response.result.forEach((element) => {
            arr.push(parseFloat(element.imp_neto));
            labels.push(element.name);
          });

          this.optionsFacturacionCategoria = {
            labels: labels,
          };
          this.seriesFacturacionCategoria = arr;
          this.$refs.statFacturacionCategoria.updateSeries(this.seriesFacturacionCategoria, false, true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGastos() {
      this.loading = true;

      this.total_gastos = 0;

      metricsService
        .getGastos({
          from_date: this.from_date_gasto,
          to_date: this.to_date_gasto,
          currency: this.currency_gasto.id,
          receiver: this.receiver_gasto != null ? this.receiver_gasto.id : null,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          var arr = [];
          var xaxis = [];

          response.result.forEach((element) => {
            this.total_gastos = this.total_gastos + parseFloat(element.amount);
            arr.push(element.amount);
            xaxis.push(this.getDateFormat(element.cbte_fch));
          });

          this.seriesGastos[0].data = arr;
          this.$refs.statGastos.updateSeries(this.seriesGastos, false, true);
          this.$refs.statGastos.updateOptions({
            xaxis: {
              categories: xaxis,
              tickAmount: 10,
            },
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);          
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getGastosByCategoria() {
      metricsService
        .getGastosByCategoria({
          from_date: this.from_date_gasto,
          to_date: this.to_date_gasto,
          currency: this.currency_gasto.id,
          receiver: this.receiver_gasto != null ? this.receiver_gasto.id : null,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          var arr = [];
          var labels = [];
          response.result.forEach((element) => {
            arr.push(parseFloat(element.imp_neto));
            labels.push(element.name);
          });

          this.optionsGastosCategoria = {
            labels: labels,
          };
          this.seriesGastosCategoria = arr;
          this.$refs.statGastosCategoria.updateSeries(this.seriesGastosCategoria, false, true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGastosByProveedor() {
      metricsService
        .getGastosByProveedor({
          from_date: this.from_date_gasto,
          to_date: this.to_date_gasto,
          currency: this.currency_gasto.id,
          receiver: this.receiver_gasto != null ? this.receiver_gasto.id : null,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          var arr = [];
          var labels = [];
          response.result.forEach((element) => {
            arr.push(parseFloat(element.amount));
            labels.push(element.name);
          });

          this.optionsGastosProveedor = {
            labels: labels,
          };
          this.seriesGastosProveedor = arr;
          this.$refs.statGastosProveedor.updateSeries(this.seriesGastosProveedor, false, true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
