<template>
  <v-card elevation="1" class="mb-4" v-if="isProductCompany()">
    <v-card-text>
      <h6 class="text-h6 black--text">Entrega</h6>
      <p class="text-body-2">Seleccione un punto de entrega o ingrese una direccion manualmente</p>
      <v-row class="mt-0" v-if="isVoucher">
        <v-col md="4" v-if="!isViewModal">
          <v-combobox
            v-model="item.shipping_type"
            data-vv-as="Tipo de entrega"
            autocomplete="off"
            name="shipping_type"
            item-text="name"
            item-value="id"
            :items="shippingTypes"
            label="Tipo de entrega"
            class="pt-0 mt-0"
            clearable
            :disabled="isViewModal"
            @change="shippingTypeChangeCombo($event)"
            filled
          ></v-combobox>
        </v-col>
        <v-col md="4">
          <v-combobox
            v-if="item.shipping_type != null && item.shipping_type.id > 1"
            v-model="item.shipping_carrier"
            data-vv-as="Transporte"
            autocomplete="off"
            name="shipping_carrier"
            item-text="name"
            item-value="id"
            :items="shippingCarriers"
            label="Transporte"
            class="pt-0 mt-0"
            clearable
            :disabled="isViewModal"
            filled
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col md="4" v-if="!isViewModal">
          <v-combobox
            v-model="item.client_shipping_address"
            data-vv-as="Puntos de entrega"
            autocomplete="off"
            name="shipping_address"
            item-text="name"
            item-value="id"
            :items="addresess"
            label="Puntos de entrega"
            v-on:change="shippingAddressChangeCombo($event)"
            class="pt-0 mt-0"
            clearable
            :disabled="isViewModal || !(this.client != undefined && this.client.id > 0)"
            filled
          ></v-combobox>
        </v-col>
        <v-col md="6">
          <v-text-field
            hide-details
            rows="3"
            outlined
            autocomplete="off"
            data-vv-as="Dirección de entrega"
            label="Dirección de entrega"
            name="shipping_address"
            v-model="item.shipping_address"
            :disabled="isViewModal"
          />
          <span class="red--text">{{ errors.first("shipping_address") }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { vouchersService } from "@/libs/ws/vouchersService";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
export default {
  components: {},
  mixins: [SettingsMixin],
  props: {
    item: Object,
    client: Object,
    isVoucher: {
      type: Boolean,
      default: false,
    },
    isViewModal: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: String,
      default: "332px",
    },
  },
  data: () => ({
    addresess: [],
    shippingTypes: [],
    shippingCarriers: [],
  }),
  watch: {
    client(val) {
      if (val) {
        this.loadShippingAddresses();
      }
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    shippingAddressChangeCombo(e) {
      console.log();
      if (e != null && e.shipping_address != null) this.item.shipping_address = e.shipping_address;
      else this.item.shipping_address = "";
    },
    shippingTypeChangeCombo(e) {
      if (e != null && e.id > 1) {
        this.item.shipping_carrier = null;
      }
    },
    loadShippingAddresses() {
      if (this.client != undefined && this.client.id > 0) {
        generalService.setSection(Constant.SECTION_CLIENTS);
        generalService
          .query({}, Constant.SECTION_CLIENTS + "/shipping_addresess/" + this.client.id)
          .then((response) => {
            this.addresess = response.result;
            this.loading = false;
          })
          .catch((e) => {
            console.log("shipping_addresess.query error");
            console.log(e);
            this.loading = false;
          });
      }
    },
    loadComboData() {
      generalService.setSection(Constant.SECTION_CARRIER);
      generalService
        .filter({ enabled: true })
        .then((response) => {
          this.shippingCarriers = response.result;
        })
        .catch((response) => {
          //this.$toast.error(response.msg, "Error");
        });

      vouchersService
        .getShippingTypes()
        .then((response) => {
          this.shippingTypes = response.result;
        })
        .catch((response) => {
          //this.$toast.error(response.msg, "Error");
        });
    },
  },
};
</script>
