<template>
  <div style="padding: 20px; min-height: 630px; background-color: rgba(221, 225, 230, 1); border: solid 1px #ccc">
    <div class="row mt-2">
      <div class="col-md-12">
        <v-combobox style="max-width: 90px; max-height: 40px" class="float-left" v-model="ano_selected" @change="getData" :items="anios" label="Año" dense></v-combobox>
        <div style="max-width: 90px; display: inline-block" class="ml-4 pt-1">
          <v-combobox v-model="currency" autocomplete="off" item-text="name" item-value="id" :items="monedas" class="pt-0 mt-0" dense label="Moneda" @change="getData"></v-combobox>
        </div>

        <div style="max-width: 350px; display: inline-block" class="ml-4">
          <v-combobox
            v-model="emitter"
            autocomplete="off"
            data-vv-as="emitter"
            name="emitter"
            item-text="name"
            item-value="id"
            :items="emitters"
            label="Emisor/Receptor"
            clearable
            class="pt-0 mt-0"
            dense
            @change="getData"
          ></v-combobox>
        </div>

        <div>
          <span class="subtitle-business-card ml-0">Los totales no incluyen IVA. </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <v-card>
          <v-card-title class="pb-0">
            <div class="col-md-10">
              <p class="title_bi mb-0">Facturación</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="col-md-2">
                  <v-icon class="ml-2 float-right" small color="grey" dark v-bind="attrs" v-on="on"> help_outline </v-icon>
                </div>
              </template>
              <span> Total facturado en el año seleccionado</span>
            </v-tooltip>
            <div class="col-md-12 pt-0">
              <v-chip outlined color="primary"> Volumen de facturacion: {{ currency.name }} {{ new Intl.NumberFormat("en-EN").format(total_facturacion) }} </v-chip>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <apexchart height="180px" type="bar" ref="statFacturacion" :options="options" :series="seriesFacturacion"></apexchart>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <div class="col-md-6">
        <v-card>
          <v-card-title class="pb-0">
            <div class="col-md-10">
              <p class="title_bi mb-0">Gastos</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="col-md-2">
                  <v-icon class="ml-2 float-right" small color="grey" dark v-bind="attrs" v-on="on"> help_outline </v-icon>
                </div>
              </template>
              <span> Total gastos en el año seleccionado</span>
            </v-tooltip>
            <div class="col-md-12 pt-0">
              <v-chip outlined color="primary"> Volumen de gastos: {{ currency.name }} {{ new Intl.NumberFormat("en-EN").format(total_gastos) }} </v-chip>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <apexchart height="180px" type="bar" ref="statGastos" :options="options" :series="seriesGastos"></apexchart>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <v-card>
          <v-card-title class="pb-0">
            <div class="col-md-10">
              <p class="title_bi mb-0">Rentabilidad</p>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="col-md-2">
                  <v-icon class="ml-2 float-right" small color="grey" dark v-bind="attrs" v-on="on"> help_outline </v-icon>
                </div>
              </template>
              <span> Rentabilidad en el año seleccionado</span>
            </v-tooltip>
            <div class="col-md-12 pt-0">
              <v-chip outlined color="primary"> Volumen de rentabilidad: {{ currency.name }} {{ new Intl.NumberFormat("en-EN").format(total_rentabilidad) }} </v-chip>
            </div>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col p-12">
                <apexchart height="250px" type="bar" ref="statRentabilidad" :options="options" :series="seriesRentabilidad"></apexchart>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { metricsService } from "@/libs/ws/metricsService.js";
import { emmiterService } from "@/libs/ws/emmiterService";

export default {
  components: {},
  data() {
    return {
      service: metricsService,
      currency: { id: "ARS", name: "ARS" },
      monedas: metricsService.getMonedas(),
      emitter: null,
      emitters: [],
      loading: false,
      menu: false,
      anios: [],
      ano_selected: new Date().getFullYear(),
      total_facturacion: 0,
      total_gastos: 0,
      total_rentabilidad: 0,
      seriesFacturacion: [
        {
          name: "Facturación",
          data: [],
        },
      ],
      seriesGastos: [
        {
          name: "Gastos",
          data: [],
        },
      ],
      seriesRentabilidad: [
        {
          name: "Rentabilidad",
          data: [],
        },
      ],
      options: {
        legend: {
          show: true,
          fontSize: "11px",
        },
        chart: {
          toolbar: {
            show: false,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
          legend: {
            show: true,
            onItemHover: {
              highlightDataSeries: true,
            },
            fontSize: "24px",
          },
        },
        fill: {
          type: "gradient",
          colors: ["#4a7def", "#E91E63", "#9C27B0"],
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 0.9,
            opacityTo: 0.9,
            stops: [0, 50, 100],
          },
        },
        xaxis: {
          categories: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return new Intl.NumberFormat("en-EN").format(val);
            },
          },
        },
        dataLabels: { enabled: false },
      },
    };
  },
  mounted() {
    this.getData();
    this.getYears();
    this.loadEmitters();
  },
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    loadEmitters() {
      emmiterService.filter({ enabled: true }).then((response) => {
        this.emitters = response.result;
      });
    },
    getYears() {
      var i = 2020;
      var ano_actual = new Date().getFullYear();
      for (i; i <= ano_actual; i++) {
        this.anios.push(i);
      }
    },
    getData() {
      this.fillFacturacion();
      this.fillGastos();
      this.fillRentabilidad();
    },
    fillFacturacion() {
      this.total_facturacion = 0;

      metricsService
        .getFacturacionByYear({
          year: this.ano_selected,
          currency: this.currency.id,
          emitter: this.emitter != null ? this.emitter.id : null,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          response.result.forEach((element) => {
            this.total_facturacion = this.total_facturacion + parseFloat(element);
          });

          this.seriesFacturacion[0].data = response.result;
          this.$refs.statFacturacion.updateSeries(this.seriesFacturacion, false, true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fillGastos() {
      this.total_gastos = 0;

      metricsService
        .getGastosByYear({
          year: this.ano_selected,
          currency: this.currency.id,
          receiver: this.emitter != null ? this.emitter.id : null,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          response.result.forEach((element) => {
            this.total_gastos = this.total_gastos + parseFloat(element);
          });

          this.seriesGastos[0].data = response.result;
          this.$refs.statGastos.updateSeries(this.seriesGastos, false, true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fillRentabilidad() {
      this.total_rentabilidad = 0;

      metricsService
        .getRentabilidadByYear({
          year: this.ano_selected,
          currency: this.currency.id,
        })
        .then((response) => {
          if(response.status === false){
            return;
          }
          response.result.forEach((element) => {
            this.total_rentabilidad = this.total_rentabilidad + parseFloat(element);
          });

          this.seriesRentabilidad[0].data = response.result;
          this.$refs.statRentabilidad.updateSeries(this.seriesRentabilidad, false, true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
