<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="700px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <p class="mb-0 mt-4"><b>Seleccione el tipo de movimiento</b></p>
                <v-radio-group v-model="item.movement_type" :disabled="isViewModal()">
                  <v-radio label="Modificación de stock" value="M"></v-radio>
                  <v-radio label="Transferencia de stock" value="T"></v-radio>
                </v-radio-group>
              </div>

              <div class="col-md-12" v-if="item.movement_type == 'M'">
                <v-combobox
                  v-model="item.warehouse_origin"
                  data-vv-as="Deposito"
                  name="deposito"
                  item-text="name"
                  item-value="id"
                  :items="warehouses"
                  label="Deposito*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("deposito") }}</span>
              </div>

              <div class="col-md-6" v-if="item.movement_type == 'T'">
                <v-combobox
                  v-model="item.warehouse_origin"
                  data-vv-as="Deposito de origen"
                  name="deposito de origen"
                  item-text="name"
                  item-value="id"
                  :items="warehouses"
                  label="Deposito de Origen*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("warehouse_origin") }}</span>
              </div>

              <div class="col-md-6" v-if="item.movement_type == 'T'">
                <v-combobox
                  v-model="item.warehouse_destination"
                  data-vv-as="Deposito de destino"
                  name="deposito de destino"
                  item-text="name"
                  item-value="id"
                  :items="warehouses"
                  label="Deposito de Destino*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("warehouse_origin") }}</span>
              </div>

              <div class="col-md-12">
                <v-combobox
                  v-model="item.product_presentation"
                  data-vv-as="Producto"
                  name="Producto"
                  :item-text="label"
                  item-value="id"
                  :items="presentations"
                  label="Producto*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("Producto") }}</span>
              </div>

              <div class="col-md-12" v-if="item.product_presentation != null">
                <v-combobox
                  v-model="item.product_presentation.presentation_type"
                  data-vv-as="Tipo de presentacion"
                  name="presentation_type"
                  item-text="name"
                  item-value="id"
                  :items="presentation_types"
                  label="Tipo de presentacion*"
                  autocomplete="off"
                  :disabled="true"
                  v-validate="'required'"
                ></v-combobox>
                <span class="red--text">{{ errors.first("presentation_type") }}</span>
              </div>

              <!--
              <div class="col-md-12">
                <p class="mb-0" v-if="item.movement_type == 'M'"><b>Seleccione el tipo de stock a modificar*</b></p>
                <p class="mb-0" v-if="item.movement_type == 'T'"><b>Seleccione el tipo de stock a transferir*</b></p>
                <v-radio-group v-model="item.stock_type" :disabled="isViewModal()" v-validate="'required'">
                  <v-radio label="Disponible" value="A"></v-radio>
                  <v-radio label="Reservado" value="R"></v-radio>
                    <v-radio label="Futuro" value="F"></v-radio>
                    <v-radio label="Bloqueado" value="B"></v-radio>

                </v-radio-group>
              </div>
              -->

              <div class="col-md-12">
                <v-text-field
                  autocomplete="off"
                  name="stock_updated"
                  label="Cantidad*"
                  data-vv-as="Cantidad"
                  :disabled="isViewModal()"
                  placeholder=""
                  :v-validate="!item.is_own ? 'required|decimal' : ''"
                  type="number"
                  v-validate="getValidationRuleCantidad()"
                  v-model="item.stock_updated"
                />
                <span class="red--text">{{ errors.first("stock_updated") }}</span>
              </div>

              <div class="col-md-12">
                <v-text-field autocomplete="off" data-vv-as="Observaciones" name="description" label="Observaciones" :disabled="isViewModal()" v-model="item.description" />
                <span class="red--text">{{ errors.first("description") }}</span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import { generalService } from "@/libs/ws/generalService";

export default {
  mixins: [ModalGenericMixin, SettingsMixin],
  components: {},
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,

    updateDialog: Function,
    loadList: Function,
  },
  data() {
    return {
      show: false,
      warehouses: [],
      presentations: [],
      presentation_types: [],
      loading: false,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog(val) {
      if (val) this.loadComboData();
      this.errors.clear();
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    label(item) {
      if (this.isProductCompany()) return item.label;
      return item.name;
    },
    getValidationRuleCantidad() {
      return this.item.movement_type == "T" ? "required|decimal|min:1" : "required|decimal";
    },
    loadComboData() {
      generalService.setSection(Constant.SECTION_WAREHOUSES);
      generalService
        .filter({ enabled: true })
        .then((response) => {
          this.warehouses = response.result;
          //Auto select default
          this.warehouses.forEach((element) => {
            if (element.default_warehouse) {
              this.item.warehouse_origin = element;
              this.item.warehouse_destination = element;
            }
          });
          this.loading = false;
        })
        .catch((e) => {
          console.log("warehouses.filter error");
          console.log(e);
          this.loading = false;
        });

      generalService.setSection(Constant.SECTION_PRODUCTS);
      generalService
        .query({ buyable: true }, Constant.SECTION_PRODUCTS + "/presentations")
        .then((response) => {
          this.presentations = response.result;
        })
        .catch((e) => {
          console.log("presentations.filter error");
        });

      generalService.query({}, Constant.SECTION_UNITS + "/list_presentation_types").then((response) => {
        this.presentation_types = response.result;
      });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    cutomValidate() {
      if (this.item.movement_type == "T" && this.item.warehouse_origin.id == this.item.warehouse_destination.id) {
        this.$toast.error("El deposito de origen y destino no pueden ser iguales", "Error");
        return false;
      }
      return true;
    },
    createItem(e) {
      generalService.setSection(Constant.SECTION_STOCKS);
      this.$validator.validate().then((valid) => {
        if (valid && this.cutomValidate()) {
          e.preventDefault();
          this.loading = true;
          this.service
            .movement(this.item)
            .then((response) => {
              console.log(response);
              if (response.status === true) {
                this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.$toast.error(response.message, "Error");
                } else {
                  this.$toast.error(response.msg, "Error");
                }
              }
            })
            .catch((response) => {
              console.log(response);
              this.$toast.error(response.msg, "Error");
            })
            .finally(() => (this.loading = false));
        }
      });
    },
  },
};
</script>
